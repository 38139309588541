/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { Helmet } from "react-helmet-async";
import "./styles/error.css";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

function error() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={`App ${localStorage.getItem("theme")}`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "تميم السهلي - صفحة غير موجودة"
              : "Tameem Al-Sahli - Page Not Found"}
          </title>
        </Helmet>
        <main id="main">
          <section className="errorSec">
            <div className="errorText">
              <h3>{i18n.language === "ar" ? "خطا 404" : "Error 404"}</h3>
              <p>
                {i18n.language === "ar"
                  ? "الصفحة غير موجودة قد تكون انحذفت او تغير مسارها"
                  : "The page does not exist, it may have been deleted or relocated"}
              </p>
              <p className="errorMobileText">
                {i18n.language === "ar"
                  ? "الصفحة غير موجودة"
                  : "Page Not Found"}
              </p>
            </div>
            <img
              className="meme1"
              src="./assets/imgs/drPhilMeme.png"
              alt="errorImg"
              style={{ width: "391px", margin: "90px auto", display: "block" }}
            />
          </section>
        </main>
      </div>
    </>
  );
}

export default error;
