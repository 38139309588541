/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./styles/Header.css";
import { BrightnessMedium, Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Menu,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Header({ setMode }) {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  // navbar menus
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // navbar menu mobile
  const [anchorElMB, setAnchorElMB] = useState(null);
  const openMB = Boolean(anchorElMB);
  const handleClickMB = (event) => {
    setAnchorElMB(event.currentTarget);
  };
  const handleCloseMB = () => {
    setAnchorElMB(null);
  };

  // navbar languages menu mobile
  const [anchorElMBLG, setAnchorElMBLG] = useState(null);
  const openMBLG = Boolean(anchorElMBLG);
  const handleClickMBLG = (event) => {
    setAnchorElMBLG(event.currentTarget);
  };
  const handleCloseMBLG = () => {
    setAnchorElMBLG(null);
  };

  return (
    <>
      <div className="headerDiv">
        {/* header */}
        <header className="transparentHeader">
          <Link to={"/"}>
            <div className="imgDiv">
              <img
                className="HeaderImg"
                src="./assets/imgs/BigTitleLogo.png"
                alt="logo"
                aria-label="logo"
              />
            </div>
          </Link>
          {/* navbars -> */}

          {/* desktop */}
          <div className="NavbarParent">
            <nav className="navbarHeader">
              <ul className="LinksUl">
                <NavLink to="/Home" className="linkLi">
                  <li>
                    {i18n.language === "ar" && "الصفحة الرئيسية"}
                    {i18n.language === "en" && "Home"}
                  </li>
                </NavLink>
                <Box
                  className="linkLi"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  // aria-controls={open ? "basic-menu" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  // onClick={handleClick}
                  onClick={() => {
                    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
                  }}
                >
                  <li>
                    {/* {i18n.language === "ar" && "اللغات"}
                    {i18n.language === "en" && "Languages"} */}
                    {i18n.language === "ar" && "English"}
                    {i18n.language === "en" && "العربية"}
                  </li>
                  {/* <ArrowDropDown sx={{ fontSize: 30 }} /> */}
                </Box>
              </ul>
            </nav>
          </div>
          {/* Languages Menu */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            onClose={handleClose}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <MenuItem
              onClick={() => {
                i18n.changeLanguage("ar");
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  margin: "0 1rem",
                  opacity: i18n.language === "ar" ? "1" : "0.7",
                }}
              >
                {i18n.language === "ar" && "العربية"}
                {i18n.language === "en" && "Arabic"}
              </ListItemText>

              <ListItemIcon>
                <Check
                  fontSize="small"
                  sx={{ opacity: i18n.language === "ar" ? "1" : "0" }}
                />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={() => {
                i18n.changeLanguage("en");
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  margin: "0 1rem",
                  opacity: i18n.language === "en" ? "1" : "0.7",
                }}
              >
                {i18n.language === "ar" && "الإنجليزية"}
                {i18n.language === "en" && "English"}
              </ListItemText>

              <ListItemIcon>
                <Check
                  fontSize="small"
                  sx={{ opacity: i18n.language === "en" ? "1" : "0" }}
                />
              </ListItemIcon>
            </MenuItem>
          </Menu>
          {/* mobile */}

          {/* other items inside navbar */}
          <div className="NavBarIcons">
            {/* <IconButton
              className="barsIcon"
              aria-label="bars button"
              aria-controls={openMB ? "basic-menuMobile" : undefined}
              aria-haspopup="true"
              aria-expanded={openMB ? "true" : undefined}
              onClick={handleClickMB}
              sx={{ color: "white" }}
            >
              <MenuIcon sx={{ fontSize: 45 }} />
            </IconButton> */}
            {/* <Menu
              id="basic-menuMobile"
              anchorEl={anchorElMB}
              open={openMB}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
              onClose={handleCloseMB}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/Home");
                  handleCloseMB();
                }}
              >
                <ListItemText
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" && "الصفحة الرئيسية"}
                  {i18n.language === "en" && "Home Page"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                aria-label="open languages menu"
                aria-controls={
                  openMBLG ? "basic-menuMobileLanguages" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openMBLG ? "true" : undefined}
                onClick={(event) => {
                  handleClickMBLG(event);
                }}
              >
                <ListItemText
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" && "اللغات"}
                  {i18n.language === "en" && "Languages"}
                </ListItemText>
              </MenuItem>
            </Menu> */}
            {/* <Menu
              id="basic-menuMobileLanguages"
              anchorEl={anchorElMBLG}
              open={openMBLG}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
              onClose={handleCloseMBLG}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("ar");
                  handleCloseMBLG();
                }}
              >
                <ListItemText
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "ar" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" && "العربية"}
                  {i18n.language === "en" && "Arabic"}
                </ListItemText>

                <ListItemIcon>
                  <Check
                    fontSize="small"
                    sx={{ opacity: i18n.language === "ar" ? "1" : "0" }}
                  />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("en");
                  handleCloseMBLG();
                }}
              >
                <ListItemText
                  sx={{
                    margin: "0 0.3rem",
                    opacity: i18n.language === "en" ? "1" : "0.7",
                  }}
                >
                  {i18n.language === "ar" && "الإنجليزية"}
                  {i18n.language === "en" && "English"}
                </ListItemText>

                <ListItemIcon>
                  <Check
                    fontSize="small"
                    sx={{ opacity: i18n.language === "en" ? "1" : "0" }}
                  />
                </ListItemIcon>
              </MenuItem>
            </Menu> */}
            <IconButton
              sx={{ color: "white" }}
              className="BrightnessIcon"
              aria-label="change mode button"
              onClick={() => {
                localStorage.setItem(
                  "currentMode",
                  theme.palette.mode === "dark" ? "light" : "dark"
                );
                setMode(theme.palette.mode === "dark" ? "light" : "dark");
              }}
            >
              <BrightnessMedium sx={{ fontSize: 40 }} />
            </IconButton>
          </div>
        </header>
      </div>
      <br />
    </>
  );
}

export default Header;
