/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import "./styles/Footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="footer-basic">
        <footer>
          <ul className="list-inline" >

            {i18n.language === "ar" && (
              <li className="list-inline-item">
                <Link to="/Home">الصفحة الرئيسية</Link>
              </li>

            )}
            {i18n.language === "en" && (
              <li className="list-inline-item">
                <Link to="/Home">Home</Link>
              </li>
            )}
            <li className="list-inline-item" style={{cursor: "pointer"}} onClick={() => {
              i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
            }}>
              <a>{i18n.language === "ar" && "English"}
                {i18n.language === "en" && "العربية"}</a>
            </li>
          </ul>
          <hr style={{ width: "65%" }} />
          <div className="sIcons_parent">
            <a
              href="https://twitter.com/whathappens_1"
              target="_blank"
              aria-label="My Twitter"
              rel="noreferrer"
            >
              <span className="icon-twitter sIcons"></span>
            </a>
            <a
              href="https://github.com/whathappens1"
              target="_blank"
              aria-label="My Github"
              rel="noreferrer"
            >
              <span className="icon-github sIcons"></span>
            </a>


            <br />
          </div>
          <p
            className="copyright"
            // @ts-ignore
            sx={{ marginTop: "13px" }}
          >
            {i18n.language === "ar" &&
              `جميع الحقوق محفوظة ${new Date().getFullYear()} © لشيخ تميم السهلي`}
            {i18n.language === "en" &&
              `All rights reserved © ${new Date().getFullYear()} Developer Tameem Al-Sahli`}
          </p>
        </footer>
      </div>
      <br />
      <br />
    </>
  );
}

export default Footer;
