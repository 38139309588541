/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import "./styles/home.css";
import { useTranslation } from "react-i18next";
import { useTheme, Typography, Stack, Divider, Box } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

function home() {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const [moreDetails1, setmoreDetails1] = useState("none");
  const [moreDetails2, setmoreDetails2] = useState("none");
  const [moreDetails3, setmoreDetails3] = useState("none");

  return (
    <>
      <div className={`App ${theme.palette.mode}`}>
        <Helmet>
          <title>
            {i18n.language === "ar"
              ? "تميم السهلي - حياك الله"
              : "Tameem Al-Sahli - Welcome"}
          </title>
          <meta
            name="description"
            content="حياك الله معك تميم السهلي مبرمج مواقع ويب محترف لي 3 سنوات تقريبا, وايضا مصمم على خفيف, تثدر تشوف شغلي من الموقع هذا, بتوفيق (:"
          />
        </Helmet>
        <main id="main">
          {/* hello & welcome section */}
          <section className="helloSec">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              sx={{
                flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
                justifyContent: "center",
                display: {xs: "none", sm: "flex"}
              }}
            >
              <Box sx={{ mx: "3rem" }}>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    fontSize: {
                      sm: "35px",
                      md: "45px",
                      lg: "60px",
                    },
                    textAlign: "center",
                  }}
                >
                  {i18n.language === "ar" && "حياك الله"}
                  {i18n.language === "en" && "Welcome"}
                </Typography>
                <br />
                <Typography
                  variant="h2"
                  component="h2"
                  className="NameOnMdScreen"
                  dir="auto"
                  sx={{
                    fontSize: "45px",
                    display: {
                      xs: "none",
                      lg: "block",
                    },
                    margin: "0 1rem",
                    textAlign: "start",
                  }}
                >
                  {i18n.language === "ar" && "معك تميم إبراهيم السهلي"}
                  {i18n.language === "en" && "With you Tameem Ibrahim Al-Sahli"}
                </Typography>
                <Typography
                  variant="h2"
                  component="h2"
                  className="NameOnMdScreen"
                  dir="auto"
                  sx={{
                    fontSize: { xs: "19px", bx: "21px", sm: "24px" },
                    display: { xs: "block", lg: "none" },
                    margin: "0 1rem",
                    textAlign: "start",
                  }}
                >
                  {i18n.language === "ar" && "معك تميم السهلي"}
                  {i18n.language === "en" && "With you Tameem Al-Sahli"}
                </Typography>
                <br />
              </Box>
              <Box sx={{ width: "45%" }}>
                {i18n.language === "ar" && (
                  <Typography
                    variant="h2"
                    dir="rtl"
                    component="h3"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        md: "20px",
                      },
                      textAlign: "center",
                      margin: "3rem 15px",
                    }}
                  >
                    مبرمج مواقع
                    <Box component="span" className="CustomWords"> UI & UX</Box>, لي تقريبا 3
                    سنوات, ومصمم على خفيف في بعض برامج ادوبي, بتوفيق (:
                  </Typography>
                )}
                {i18n.language === "en" && (
                  <Typography
                    variant="h2"
                    dir="ltr"
                    component="h3"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        md: "20px",
                      },
                      textAlign: "center",
                      margin: "3rem 15px",
                    }}
                  >
                    Website programmer
                    <span className="CustomWords"> UI & UX</span>, I have been
                    working for almost 3 years, and I am also a designer in some
                    Adobe programs, have a nice day (:
                  </Typography>
                )}
                <br />
              </Box>
            </Stack>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                display: {xs: "flex", sm: "none"}
              }}
            >
              <Box sx={{ mx: "3rem" }}>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                >
                  {i18n.language === "ar" && "حياك الله"}
                  {i18n.language === "en" && "Welcome"}
                </Typography>
                <Typography
                  variant="h2"
                  component="h2"
                  className="NameOnMdScreen"
                  sx={{
                    fontSize: { xs: "24px", bx: "26px", sm: "28px" },
                    display: { xs: "block", lg: "none" },
                    margin: "0 1rem",
                    textAlign: "center",
                  }}
                >
                  {i18n.language === "ar" && "معك تميم السهلي"}
                  {i18n.language === "en" && "With you Tameem Al-Sahli"}
                </Typography>
                <br />
              </Box>
              <Box sx={{ width: "90%" }}>
                {i18n.language === "ar" && (
                  <Typography
                    variant="h2"
                    component="h3"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        md: "20px",
                      },
                      textAlign: "center",
                      margin: "2rem 1rem",
                    }}
                  >
                    مبرمج مواقع
                    <Box component="span" className="CustomWords"> UI & UX</Box>, لي تقريبا 3
                    سنوات, ومصمم على خفيف في بعض برامج ادوبي, بتوفيق (:
                  </Typography>
                )}
                {i18n.language === "en" && (
                  <Typography
                    variant="h2"
                    dir="ltr"
                    component="h3"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        md: "20px",
                      },
                      textAlign: "center",
                      margin: "3rem 15px",
                    }}
                  >
                    Website programmer
                    <span className="CustomWords"> UI & UX</span>, I have been
                    working for almost 3 years, and I am also a designer in some
                    Adobe programs, have a nice day (:
                  </Typography>
                )}
                <br />
              </Box>
            </Stack>
            <div className="sIcons_parent" style={{ marginTop: "2.5rem" }}>
              <a
                href="https://twitter.com/whathappens_1"
                target="_blank"
                aria-label="My Twitter"
                rel="noreferrer"
              >
                <span className="icon-twitter sIcons"></span>
              </a>
              <a
                href="https://github.com/whathappens1"
                target="_blank"
                aria-label="My Github"
                rel="noreferrer"
              >
                <span className="icon-github sIcons"></span>
              </a>
              <br />
            </div>
            <br />
          </section>
          {/* languages section */}
          <section className="skillsSec">
            <Typography
              variant="body1"
              component="p"
              className="myAbout"
              sx={{
                fontSize: { xs: "35px", md: "45px" },
                margin: "30px",
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar" && "البرمجة:"}
              {i18n.language === "en" && "Programming:"}
            </Typography>
            <div className="languageParent">
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/htmlLogo.png"
                  loading="lazy"
                  alt="HTML_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  HTML
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      backgroundColor: "rgb(22 217 22)",
                      width: "96%",
                      boxShadow: "0 10px 40px -10px rgb(22 217 22)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "96% جامد"}
                  {i18n.language === "en" && "Very Good 96%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/cssLogo.png"
                  loading="lazy"
                  alt="CSS_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  CSS
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(47 215 47)",
                      width: "92%",
                      boxShadow: "0 10px 40px -10px rgb(47 215 47)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "92% محترف"}
                  {i18n.language === "en" && "Professional 92%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/jsLogo.png"
                  loading="lazy"
                  alt="JAVESCRIPT_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  Javescript
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "#91d313",
                      width: "70%",
                      boxShadow: "0 10px 40px -10px #91d313",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "70% لا باس"}
                  {i18n.language === "en" && "It's Okay 70%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/node.jsLogo.png"
                  loading="lazy"
                  alt="NODE.JS_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  Node.Js
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(145 209 24)",
                      width: "65%",
                      boxShadow: "0 10px 40px -10px rgb(145 209 24)b",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "65% تحسنت شوي"}
                  {i18n.language === "en" && "I improved a little 65%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/reactLogo.png"
                  loading="lazy"
                  alt="REACT_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  React
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(22, 217, 22)",
                      width: "95%",
                      boxShadow: "0 10px 40px -10px rgb(22, 217, 22)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "95% كويس"}
                  {i18n.language === "en" && "Good 95%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/tailwindLogo.png"
                  loading="lazy"
                  alt="TAILWIND_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  {i18n.language === "ar" && "Tailwind مكتبة"}
                  {i18n.language === "en" && "Tailwind Library"}
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "#91d313",
                      width: "70%",
                      boxShadow: "0 10px 40px -10px #91d313",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "70% ليس سيء"}
                  {i18n.language === "en" && "Not Bad 70%"}
                </Typography>
              </div>
              <div className="languageItem">
                <img
                  src="./assets/imgs/languagesLogos/muiLogo.png"
                  loading="lazy"
                  alt="MATERIAL_UI_Logo"
                  style={{ width: "70px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageName"
                  fontWeight="600"
                >
                  {i18n.language === "ar" && "Material UI مكتبة"}
                  {i18n.language === "en" && "Material UI Library"}
                </Typography>

                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(22 217 22)",
                      width: "94%",
                      boxShadow: "0 10px 40px -10px rgb(22 217 22)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="LanguageLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "94% ممتاز"}
                  {i18n.language === "en" && "Very Good 94%"}
                </Typography>
              </div>
            </div>
            <br />
          </section>
          {/* languages example section */}
          <div className="LanguagesExamples">
            <Typography
              variant="body1"
              component="p"
              className="myAbout"
              sx={{
                fontSize: { xs: "35px", md: "45px" },
                margin: "30px",
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar" && "امثلة:"}
              {i18n.language === "en" && "Examples:"}
            </Typography>
            <div className="LanguagesExamplesParent">
              <div
                className="LanguageExample"
                style={{
                  height: moreDetails1 === "block" ? "41rem" : "32rem",
                }}
              >
                <a
                  href="https://mahamuk-sahli.web.app/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src="./assets/imgs/projectsImgs/projectImg1.png"
                    alt="LanguageImageProject"
                  />
                </a>

                <div className="LanguageExampleCustomHr"></div>
                <Typography
                  variant="h5"
                  className="textProjectName"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "مشروع مهامك"}
                  {i18n.language === "en" && "Mahamuk Project"}
                </Typography>
                <Typography
                  variant="h5"
                  className="textProjectDes"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "مشروع ينظم مهامك الخاصة"}
                  {i18n.language === "en" && "Project organize your tasks"}
                </Typography>
                <div className="moreDetails" style={{ display: moreDetails1 }}>
                  <br />
                  <Typography
                    variant="h5"
                    className="title"
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  >
                    {i18n.language === "ar" && "مميزات الموقع:"}
                    {i18n.language === "en" && "Site Features:"}
                  </Typography>
                  <Stack>
                    <ul
                      className="GoodOptions"
                      dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    >
                      <li>
                        {i18n.language === "ar" && "اللغات المتعددة"}
                        {i18n.language === "en" && "Multi Languages"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "ميزة الوضع الداكن"}
                        {i18n.language === "en" && "Dark Mode Feature"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "تسجيل حساب بجميع اشكاله"}
                        {i18n.language === "en" && "Sign In, Sign Up..."}
                      </li>
                      <li>
                        {i18n.language === "ar" && "التواصل مع قاعدة البيانات"}
                        {i18n.language === "en" && "Connect with the Database"}
                      </li>
                    </ul>
                  </Stack>
                  <br />
                  <br />
                </div>
                <div
                  className="showMore"
                  onClick={() => {
                    setmoreDetails1(
                      moreDetails1 === "block" ? "none" : "block"
                    );
                  }}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <Typography variant="h6" component="p">
                    {i18n.language === "ar" &&
                      (moreDetails1 === "block"
                        ? "اخفاء التفاصيل"
                        : "اظهار المزيد من التفاصيل")}
                    {i18n.language === "en" &&
                      (moreDetails1 === "block"
                        ? "Hide Details"
                        : "Show More Details")}
                  </Typography>
                  <div>
                    {moreDetails1 === "block" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    )}
                  </div>
                </div>
                <div
                  className="githubIcon"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <a
                    href="https://github.com/whathappens1/MahamukFinalProject"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="icon-github"></span>
                  </a>
                </div>
                <div className="link">
                  <a
                    href="https://mahamuk-sahli.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                      {i18n.language === "ar" && "زيارة الموقع"}
                      {i18n.language === "en" && "Visit Site"}
                    </p>
                  </a>
                </div>
              </div>
              <div
                className="LanguageExample"
                style={{
                  height: moreDetails2 === "block" ? "41rem" : "32rem",
                }}
              >
                <a
                  href="https://nicecaffeine.web.app/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src="./assets/imgs/projectsImgs/projectImg2.png"
                    alt="LanguageImageProject"
                  />
                </a>

                <div className="LanguageExampleCustomHr"></div>
                <Typography
                  variant="h5"
                  className="textProjectName"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "مشروع نايس كافيين"}
                  {i18n.language === "en" && "Nice Caffeine Project"}
                </Typography>
                <Typography
                  variant="h5"
                  className="textProjectDes"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "موقع تواصل اجتماعي"}
                  {i18n.language === "en" && "Social Media Site"}
                </Typography>
                <div className="moreDetails" style={{ display: moreDetails2 }}>
                  <br />
                  <Typography
                    variant="h5"
                    className="title"
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  >
                    {i18n.language === "ar" && "مميزات الموقع:"}
                    {i18n.language === "en" && "Site Features:"}
                  </Typography>
                  <Stack>
                    <ul
                      className="GoodOptions"
                      dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    >
                      <li>
                        {i18n.language === "ar" && "مشروع لا يعمل, فقط شكل !"}
                        {i18n.language === "en" &&
                          "This Project Doesn't Work, Only Design"}
                      </li>
                      <li>
                        {i18n.language === "ar" &&
                          "ان شاء الله قريب بخليه يشتغل.."}
                        {i18n.language === "en" &&
                          "Soon I will change it, to make it work.."}
                      </li>
                      <li>
                        {i18n.language === "ar" && "اللغات المتعددة"}
                        {i18n.language === "en" && "Multi Languages"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "ميزة الوضع الداكن"}
                        {i18n.language === "en" && "Dark Mode Feature"}
                      </li>
                    </ul>
                  </Stack>
                  <br />
                  <br />
                </div>
                <div
                  className="showMore"
                  onClick={() => {
                    setmoreDetails2(
                      moreDetails2 === "block" ? "none" : "block"
                    );
                  }}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <Typography variant="h6" component="p">
                    {i18n.language === "ar" &&
                      (moreDetails2 === "block"
                        ? "اخفاء التفاصيل"
                        : "اظهار المزيد من التفاصيل")}
                    {i18n.language === "en" &&
                      (moreDetails2 === "block"
                        ? "Hide Details"
                        : "Show More Details")}
                  </Typography>
                  <div>
                    {moreDetails2 === "block" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    )}
                  </div>
                </div>
                <div
                  className="githubIcon"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <a
                    href="https://github.com/whathappens1/NiceCaffeine/tree/Version-1.0.1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="icon-github"></span>
                  </a>
                </div>
                <div className="link">
                  <a
                    href="https://nicecaffeine.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                      {i18n.language === "ar" && "زيارة الموقع"}
                      {i18n.language === "en" && "Visit Site"}
                    </p>
                  </a>
                </div>
              </div>
              <div
                className="LanguageExample"
                style={{
                  height: moreDetails3 === "block" ? "41rem" : "32rem",
                }}
              >
                <a
                  href="https://fakeonlinestore2023.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src="./assets/imgs/projectsImgs/projectImg3.png"
                    alt="LanguageImageProject"
                  />
                </a>

                <div className="LanguageExampleCustomHr"></div>
                <Typography
                  variant="h5"
                  className="textProjectName"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "مشروع متجر إلكتروني"}
                  {i18n.language === "en" && "Online Store Project"}
                </Typography>
                <Typography
                  variant="h5"
                  className="textProjectDes"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {i18n.language === "ar" && "متجر لبيع الأجهزة الإلكترونية"}
                  {i18n.language === "en" && "An Electronics Store"}
                </Typography>
                <div className="moreDetails" style={{ display: moreDetails3 }}>
                  <br />
                  <Typography
                    variant="h5"
                    className="title"
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                  >
                    {i18n.language === "ar" && "مميزات الموقع:"}
                    {i18n.language === "en" && "Site Features:"}
                  </Typography>
                  <Stack>
                    <ul
                      className="GoodOptions"
                      dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    >
                      <li>
                        {i18n.language === "ar" && "مشروع لا يعمل, فقط شكل !"}
                        {i18n.language === "en" &&
                          "This Project Doesn't Work, Only Design"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "تعامل مع قاعدة بيانات محلية"}
                        {i18n.language === "en" && "Work with a local database"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "متقبل الشاشات"}
                        {i18n.language === "en" && "Fully Responsive"}
                      </li>
                      <li>
                        {i18n.language === "ar" && "ميزة الوضع الداكن"}
                        {i18n.language === "en" && "Dark Mode Feature"}
                      </li>
                    </ul>
                  </Stack>
                  <br />
                  <br />
                </div>
                <div
                  className="showMore"
                  onClick={() => {
                    setmoreDetails3(
                      moreDetails3 === "block" ? "none" : "block"
                    );
                  }}
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <Typography variant="h6" component="p">
                    {i18n.language === "ar" &&
                      (moreDetails3 === "block"
                        ? "اخفاء التفاصيل"
                        : "اظهار المزيد من التفاصيل")}
                    {i18n.language === "en" &&
                      (moreDetails3 === "block"
                        ? "Hide Details"
                        : "Show More Details")}
                  </Typography>
                  <div>
                    {moreDetails3 === "block" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    )}
                  </div>
                </div>
                <div
                  className="githubIcon"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  <a
                    href="https://github.com/whathappens1/Fake-Online-Store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="icon-github"></span>
                  </a>
                </div>
                <div className="link">
                  <a
                    href="https://fakeonlinestore2023.netlify.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                      {i18n.language === "ar" && "زيارة الموقع"}
                      {i18n.language === "en" && "Visit Site"}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* design section */}
          <section className="skillsSec">
            <Typography
              variant="body1"
              component="p"
              className="myAbout"
              sx={{
                fontSize: { xs: "35px", md: "45px" },
                margin: "30px",
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar" && "فن التصميم:"}
              {i18n.language === "en" && "Design Art:"}
            </Typography>
            <div className="designParent">
              <div className="designItem">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  loading="lazy"
                  alt="PHOTOSHOP_Logo"
                  style={{ width: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignAppName"
                  fontWeight="600"
                >
                  Adobe Photoshop
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(47 215 47)",
                      width: "85%",
                      boxShadow: "0 10px 40px -10px rgb(47 215 47)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "85% كويس"}
                  {i18n.language === "en" && "Good 85%"}
                </Typography>
              </div>
              <div className="designItem">
                <img
                  src="./assets/imgs/adobe_apps/adobe_encoder.png"
                  loading="lazy"
                  alt="ENCODER_Logo"
                  style={{ width: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignAppName"
                  fontWeight="600"
                >
                  Adobe Encoder
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "rgb(53 209 34)",
                      width: "80%",
                      boxShadow: "0 10px 40px -10px rgb(53 209 34)",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "80% اعرف اشتغل عليه"}
                  {i18n.language === "en" && "I know work on it 85%"}
                </Typography>
              </div>
              <div className="designItem">
                <img
                  src="./assets/imgs/adobe_apps/adobe_premiere.png"
                  loading="lazy"
                  alt="PREMIERE_Logo"
                  style={{ width: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignAppName"
                  fontWeight="600"
                >
                  Adobe Premiere Pro
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "#91d313",
                      width: "70%",
                      boxShadow: "0 10px 40px -10px #91d313",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "70% فنان لكن ساحب عليه"}
                  {i18n.language === "en" && "Good, but I left 70%"}
                </Typography>
              </div>
              <div className="designItem">
                <img
                  src="./assets/imgs/adobe_apps/adobe_after_effect.png"
                  loading="lazy"
                  alt="AFTEREFFECT_Logo"
                  style={{ width: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignAppName"
                  fontWeight="600"
                >
                  Adobe After Effect
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={{
                      background: "orange",
                      width: "65%",
                      boxShadow: "0 10px 40px -10px orange",
                    }}
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "65% من زمان عليه"}
                  {i18n.language === "en" && "I left for a long time 65%"}
                </Typography>
              </div>
              <div className="designItem">
                <img
                  src="./assets/imgs/adobe_apps/adobe_illustrator.png"
                  loading="lazy"
                  alt="ILLUSTRATOR_Logo"
                  style={{ width: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignAppName"
                  fontWeight="600"
                >
                  Adobe Illustrator
                </Typography>
                <div className="progress">
                  <div
                    className="progress-value"
                    style={
                      {
                        // background: "rgb(47 215 47)",
                        // width: "0%",
                        // boxShadow: "0 10px 40px -10px rgb(47 215 47)",
                      }
                    }
                  ></div>
                </div>
                <Typography
                  variant="h6"
                  component="p"
                  className="DesignLevelType"
                  mt="10px"
                >
                  {i18n.language === "ar" && "0% قريبا ان شاء الله"}
                  {i18n.language === "en" && "I will learn soon 0%"}
                </Typography>
              </div>
            </div>
            <br />
          </section>
          {/* design example section */}
          <div className="adobeAppsExamples">
            <Typography
              variant="body1"
              component="p"
              className="myAbout"
              sx={{
                fontSize: { xs: "35px", md: "45px" },
                margin: "30px",
              }}
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              {i18n.language === "ar" && "امثلة:"}
              {i18n.language === "en" && "Example:"}
            </Typography>
            <br />
            <div className="adobeAppsExamplesParent">
              <div className="AdobeExmParent">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppExample"
                />
                <img
                  src="./assets/imgs/adobe_apps/chessMobileSc.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppsExampleImg"
                />
              </div>
              <div className="AdobeExmParent">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppExample"
                />
                <img
                  src="./assets/imgs/adobe_apps/bobDrawing30s.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppsExampleImg"
                />
              </div>
              <div className="AdobeExmParent">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppExample"
                />
                <img
                  src="./assets/imgs/adobe_apps/Lo7Drawing30s.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppsExampleImg"
                />
              </div>
              <div className="AdobeExmParent">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppExample"
                />
                <img
                  src="./assets/imgs/adobe_apps/MushroomDrawing30sWithoutBook&WithSm.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppsExampleImg"
                />
              </div>
              <div className="AdobeExmParent">
                <img
                  src="./assets/imgs/adobe_apps/adobe_photoshop.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppExample"
                />
                <img
                  src="./assets/imgs/adobe_apps/SaharDrawing30s.png"
                  alt=""
                  loading="lazy"
                  className="adobeAppsExampleImg"
                />
              </div>
            </div>
          </div>
        </main>
        <p className="contactWithEmail" dir="rtl">
          {i18n.language === "ar"
            ? "للتواصل عبر البريد الالكتروني: Tameem.Al-Sahli@qabani.com"
            : "To contact via email: Tameem.Al-Sahli@qabani.com"}
        </p>
      </div>
    </>
  );
}

export default home;
